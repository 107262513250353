import React from 'react';
import Odometer from 'react-odometerjs';
import { useTranslation } from 'react-i18next';
export default function Dollars({ stats }) {
  const { t } = useTranslation();
  return (
    <div className="dollars">
      <div>
        <Odometer value={stats.donated} format="(,ddd)" duration={500} />{' '}
        {t('raised')}
      </div>
      <div>
        <Odometer
          value={stats.totalStats.spend / 1000000}
          format="(,ddd).dd"
          duration={500}
        />{' '}
        {t('spent')}
      </div>
    </div>
  );
}
