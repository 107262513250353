import React from 'react';
import './Language.scss';
import { useTranslation } from 'react-i18next';

const langs = [
  {
    id: 'en',
    label: 'ENG',
    active: (isEspaniol) => !isEspaniol,
  },
  {
    id: 'es',
    label: 'ESP',
    active: (isEspaniol) => isEspaniol,
  },
];

export default function Language() {
  const { i18n } = useTranslation();
  const isEspaniol = i18n.language.startsWith('es');
  return (
    <div className="Language">
      <ul>
        {langs.map((lang) => (
          <li key={lang.id} className={lang.active(isEspaniol) ? 'active' : ''}>
            <a href={`/${lang.id}`} onClick={changeLanguage(lang.id)}>
              {lang.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  function changeLanguage(langId) {
    return (event) => {
      event.preventDefault();
      i18n.changeLanguage(langId);
      window.history.pushState(null, null, event.target.href);
    };
  }
}
