let logos = [];
const subscribers = new Map();

fetchDonors();
setInterval(fetchDonors, 60000);

async function fetchDonors() {
  try {
    const response = await fetch('./donors.json');
    if (response.status !== 200) {
      throw new Error(`Status ${response.status}`);
    }
    const newLogos = await response.json();
    if (newLogos.some((l) => !l.src)) {
      throw new Error('All donor logos must have src attribute');
    }
    setLogos(addClassNames(newLogos));
  } catch (e) {
    console.error('Invalid donors.json', e);
  }
}

function setLogos(newLogos) {
  logos = newLogos;
  for (let callback of subscribers.values()) {
    callback(logos);
  }
}

export function subscribe(id, callback) {
  subscribers.set(id, callback);
  callback(logos);
}

export function unsubscribe(id) {
  subscribers.delete(id);
}

function addClassNames(logos) {
  return logos.map((l) =>
    l.alt
      ? {
          ...l,
          className: `donor-logo-${l.alt.toLowerCase().replace(/[^a-z]/g, '')}`,
        }
      : l
  );
}
