import React, { useState } from 'react';

import { ReactComponent as HandBackground } from 'icons/hand.svg';
import { ReactComponent as TapHandBackground } from 'icons/tap-hand.svg';
import 'react-responsive-carousel/lib/styles/carousel.css';
import './How.scss';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation, Trans } from 'react-i18next';

export default function How() {
  const [slide, setSlide] = useState(0);
  const { t } = useTranslation();

  function onSlideChange(slide) {
    // setTimeout(() => setSlide(slide), 350);
    setSlide(slide);
  }
  return (
    <div className="How">
      <Carousel
        className={`slide${slide}`}
        showArrows={true}
        showStatus={false}
        showIndicators={true}
        showThumbs={false}
        onChange={onSlideChange}
      >
        <div className="slide-browse-app">
          <p>
            <Trans>
              Step 1: The user browses any App. A video of how to prevent
              COVID-19 pops up in the user's native tongue.
            </Trans>
          </p>
          <div className="graphic">
            <img src="step1.png" alt="favourite app" />
          </div>
        </div>
        <div>
          <p>
            {t(
              'Step 2: The user views the video and gets reliable information on how to act to prevent the virus.'
            )}
          </p>
          {slide === 1 ? (
            <div className="graphic" style={{ height: 432 }}>
              <iframe
                className="creative"
                src="creative/covid19.htm?video"
              ></iframe>
              <HandBackground className="bg" />
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          <p>
            {t(
              'Step 3: Additional information is presented as well as a direct call-to-action leading to the WHO WhatsApp line in the user’s native tongue.'
            )}
          </p>
          {slide === 2 ? (
            <div className="graphic" style={{ height: 430 }}>
              <iframe
                className="creative tap"
                style={{ height: 782, bottom: -170, width: 364, left: -16 }}
                src="creative/covid19.htm?carousel"
              ></iframe>
              <TapHandBackground className="bg" />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="slide-whatsapp-chat">
          <p>
            {t(
              'Step 4: The user clicks on the WhatsApp call-to-action and reaches the WHO. The user can ask questions and receive reliable information and news provided by the WHO. The chatbot remains permanently active.'
            )}
          </p>
          {slide === 3 ? (
            <div className="graphic" style={{ height: 432 }}>
              <img src="Gifchatbot.gif" alt="whatsapp chat" />
              <HandBackground className="bg" />
            </div>
          ) : (
            ''
          )}
        </div>
      </Carousel>
    </div>
  );
}
