import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import es from './es.json';
import en from './en.json';

const options = {
  resources: {
    es: { translation: es },
    en: { translation: en },
  },
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',

  keySeparator: false,
  nsSeparator: false,

  interpolation: {
    escapeValue: false,
  },
};

function getLngFromUrl(callback) {
  const lng = window.location.pathname.substr(1);
  ['en', 'es'].includes(lng) && callback(lng);
}

getLngFromUrl((lng) => (options.lng = lng));

i18n.use(LanguageDetector).use(initReactI18next).init(options);

window.onpopstate = () => getLngFromUrl((lng) => i18n.changeLanguage(lng));

export default i18n;
