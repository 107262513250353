import React, { useState, useEffect } from 'react';
import * as logosSvc from './logos';

let lastId = 0;
export default function Donors() {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const id = lastId++;
    logosSvc.subscribe(id, setLogos);
    return () => logosSvc.unsubscribe(id);
  }, []);

  if (!logos.length) {
    return null;
  }

  return (
    <ul>
      {logos.map((logo, index) => (
        <li key={index}>
          <img {...logo} />
        </li>
      ))}
    </ul>
  );
}
