import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import 'odometer/themes/odometer-theme-minimal.css';
import './Map.scss';
import {
  Map as LeafletMap,
  TileLayer,
  CircleMarker,
  Tooltip,
  ZoomControl,
} from 'react-leaflet';
import Odometer from 'react-odometerjs';

const MIN_CIRCLE_RADIUS = 2;
const MAX_CIRCLE_RADIUS = 40;
const MAP_CENTER = window.innerWidth < 700 ? [20.9, 15.6] : [-3.7, -22];
const MIN_ZOOM = 3;
const MAX_ZOOM = 5;
const MAP_IMAGES_BASE_URL = 'https://appsfightcovid.kayzen.io/images';

const INIT_HEIGHT = window.innerHeight;

function Map(props) {
  const { stats } = props;
  const { t, i18n } = useTranslation();
  const [height, setHeight] = useState(INIT_HEIGHT);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
      // weird but it fixes iphone switching orientaton
      setTimeout(() => setHeight(window.innerHeight), 100);
    });
  }, []);
  return (
    <div className="map-container" style={{ height }}>
      <LeafletMap
        center={MAP_CENTER}
        zoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        zoomControl={false}
        scrollWheelZoom={false}
        attributionControl={null}
      >
        <TileLayer url={`${MAP_IMAGES_BASE_URL}/1/{z}/{x}/{y}.png`} />
        <TileLayer url={`${MAP_IMAGES_BASE_URL}/2/{z}/{x}/{y}.png`} />
        <ZoomControl position="topright"></ZoomControl>
        {stats
          ? stats.countryStats.map(
              ({
                countryCode,
                lat,
                long,
                clicks,
                impressions,
                peopleReached,
              }) => (
                <CircleMarker
                  key={countryCode}
                  center={[lat, long]}
                  color="#339991"
                  radius={
                    MIN_CIRCLE_RADIUS +
                    (impressions / stats.maxImpressions) *
                      (MAX_CIRCLE_RADIUS - MIN_CIRCLE_RADIUS)
                  }
                >
                  <Tooltip direction="auto">
                    <strong>{t(countryCode)}</strong>
                    <br />
                    <strong>
                      <Odometer
                        value={impressions}
                        format="(,ddd)"
                        duration={500}
                      />
                    </strong>{' '}
                    {t('videos viewed')}
                    <br />
                    <strong>
                      <Odometer
                        value={peopleReached}
                        format="(,ddd)"
                        duration={500}
                      />
                    </strong>{' '}
                    {t('people reached')}
                    <br />
                    <strong>
                      <Odometer value={clicks} format="(,ddd)" duration={500} />
                    </strong>{' '}
                    {t('chatbot conversations initiated')}
                  </Tooltip>
                </CircleMarker>
              )
            )
          : ''}
      </LeafletMap>
      {stats ? (
        <div className="totals">
          <div className="stat days">
            <strong>{stats.daysSinceLaunch}</strong>{' '}
            <div>
              <Trans>
                Days
                <br /> since launch
              </Trans>
            </div>
          </div>
          <div className="stat dollars">
            <div>
              <Odometer value={stats.donated} format="(,ddd)" duration={500} />{' '}
              {t('raised')}
            </div>
            <div>
              <Odometer
                value={stats.totalStats.spend / 1000000}
                format="(,ddd).dd"
                duration={500}
              />{' '}
              {t('spent')}
            </div>
          </div>
          <div className="stat">
            <strong>
              <Odometer
                value={stats.totalStats.impressions}
                format="(,ddd)"
                duration={500}
              />
            </strong>
            <div>{t('Total videos viewed')}</div>
          </div>
          <div className="stat">
            <strong>
              <Odometer
                value={stats.totalStats.peopleReached}
                format="(,ddd)"
                duration={500}
              />
            </strong>
            <div>{t('Total people reached')}</div>
          </div>
          <div className="stat">
            <strong>
              <Odometer
                value={stats.totalStats.clicks}
                format="(,ddd)"
                duration={500}
              />
            </strong>
            <div>{t('Chatbot conversations initiated')}</div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default Map;
