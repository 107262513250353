import React, { useState, useEffect, useRef } from 'react';
import './App.scss';
import _vars from './_vars';
import Map from 'components/Map/Map';
import Header from 'components/Header/Header';
import countriesDb from './countries.json';
import { ReactComponent as KayzenLogo } from 'icons/kayzen-logo.svg';
import moment from 'moment';
import StickySidebar from 'sticky-sidebar';
import About from 'components/About/About';
import How from 'components/How/How';
import Donors from 'components/Donors/Donors';
import Dollars from 'components/Dollars/Dollars';
import { useTranslation } from 'react-i18next';

const INC_STATS_INTERVAL = 5000;

function App() {
  const [stats, setStats] = useState(null);
  const [styles, setStyles] = useState(_vars());
  const leftSidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setStyles((styles) =>
        window.innerHeight === styles.innerHeight ? styles : _vars()
      );
    });
  }, []);

  useEffect(() => {
    const options = {
      innerWrapperSelector: '.sidebar__inner',
      topSpacing: styles.spacing + styles.barHeight,
      bottomSpacing: styles.spacing,
    };
    new StickySidebar(leftSidebarRef.current, options);
    new StickySidebar(rightSidebarRef.current, options);
  }, [leftSidebarRef, rightSidebarRef, styles]);

  useEffect(() => {
    requestStats();
    let id = setInterval(requestStats, 60000);
    return () => clearInterval(id);

    async function requestStats() {
      const data = await Promise.all([
        fetch('/db.json').then((r) => r.json()),
        fetch('/donated.json').then((r) => r.json()),
      ]).then((r) => ({ ...r[0], ...r[1] }));
      data.countryStats = data.countryStats
        .filter((c) => countriesDb[c.countryCode])
        .map((c) => ({ ...c, ...countriesDb[c.countryCode] }));
      setStats(incStats(data));
    }
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setStats((stats) => stats && incStats(stats));
    }, INC_STATS_INTERVAL);
    return () => clearInterval(id);
  }, []);

  const sidebarInnerStyles = {
    maxHeight: styles.innerHeight - styles.spacing - styles.barHeight,
    paddingBottom: styles.spacing,
  };

  return (
    <div className="App">
      <Header />
      <div className="content">
        <Map stats={stats} />
        <div className="meta-container">
          <div className="left-sidebar sidebar" ref={leftSidebarRef}>
            <div className="sidebar__inner" style={sidebarInnerStyles}>
              <div className="left-sidebar-inner sidebar-inner">
                <h3>{t('Donations')}</h3>
                {stats ? <Dollars stats={stats} /> : ''}
                <Donors />
                <div className="sidebar-footer">
                  <a href="https://kayzen.io/why-kayzen">
                    {t('The people behind the initiative')} <KayzenLogo />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="center-content">
            <About />
          </div>
          <div className="right-sidebar sidebar" ref={rightSidebarRef}>
            <div className="sidebar__inner" style={sidebarInnerStyles}>
              <div className="right-sidebar-inner sidebar-inner">
                <h3>{t('How it works')}</h3>
                <How />
              </div>
              <div className="sidebar-inner">
                <a
                  className="twitter-timeline"
                  href="https://twitter.com/AppsCovid?ref_src=twsrc%5Etfw"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <h3>{t('Donors')}</h3>
        <Donors />
      </div>
    </div>
  );
}

export default App;

function incStats({ timestamp, totalStats, countryStats, startedOn, ...rest }) {
  let secondsPassed = timestamp
    ? moment().diff(moment(timestamp), 'seconds')
    : 0;
  countryStats = countryStats.map((c) => incStatsRow(c, secondsPassed));
  return {
    timestamp: new Date().getTime(),
    totalStats: incStatsRow(totalStats, secondsPassed),
    countryStats,
    maxImpressions: countryStats.reduce(
      (r, i) => (r < i.impressions ? i.impressions : r),
      0
    ),
    startedOn,
    daysSinceLaunch: moment().diff(moment(startedOn), 'days'),
    ...rest,
  };
}

function incStatsRow(row, secondsPassed) {
  return {
    ...row,
    impressions:
      row.impressions + (row.impressionsPerSecond || 0) * secondsPassed,
    clicks: row.clicks + (row.clicksPerSecond || 0) * secondsPassed,
    spend: row.spend + (row.spendPerSecond || 0) * secondsPassed,
    peopleReached:
      row.peopleReached + (row.peopleReachedPerSecond || 0) * secondsPassed,
  };
}
