import React from 'react';
import './About.scss';
import { useTranslation, Trans } from 'react-i18next';

function About() {
  const { t } = useTranslation();
  return (
    <div className="About">
      <Trans parent="h1" style={{ textAlign: 'center' }}>
        Join the fight against Coronavirus by helping us reach more than
        100,000,000 people and flatten the curve
      </Trans>
      <Trans parent="p">
        By donating to #AppsFightCovid you assist in spreading reliable
        information in countries where the COVID-19 breakout is at its earlier
        stage. Changing people’s behaviour is a critical measure to avoid
        further spreading of the virus. We target developing countries where
        people can be exposed to unreliable information.
      </Trans>
      <Trans parent="h2">How Do We Spread Reliable Information?</Trans>
      <Trans parent="p">
        By targeting people through mobile devices. We created ads with an
        informative video provided by the World Health Organization (WHO) that
        explains how to prevent infection. We translated these ads from English
        into Arabic, French, Portuguese and Spanish targeting more than 50
        countries in Latin America and Africa. Our technology enables us to
        immediately reach people right on their mobile phones, regardless of
        where in the world they are. To learn more about this, check out the
        “How it works” section.
      </Trans>
      <Trans parent="h2">How Can You Help?</Trans>
      <Trans parent="p">
        By donating even a small amount of money - you can make a significant
        change. With only 1$ we can reach 1,000 more people, meaning with 1,000$
        we can reach 1 million more people. We owe gratitude to companies and
        individuals for donating to our initiative. Join the fight today and
        help us reach millions of people.
      </Trans>
      <p className="donate-container">
        <a
          className="donate"
          href="https://kayzen-programmatic.typeform.com/to/cFID6e"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('DONATE')}
        </a>
      </p>
      <Trans parent="h2">Read What Our Donors Say About Our Initiative:</Trans>
      <Trans parent="blockquote">
        <strong>Fastic - Benjamin Bak, Co-Founder:</strong> “As we see how
        difficult the outbreaks are already in developed countries, we should
        not forget to look at the situation of people in developing countries –
        the situation there is even worse as the healthcare systems are already
        overloaded”.
      </Trans>
      <Trans parent="blockquote">
        <strong>AppLovin - Idil Canal, General Manager:</strong> “We are excited
        to contribute to this initiative and believe the campaign message is
        strong. Using technology to drive awareness and reach millions of people
        is an effective way to raise awareness and quickly multiply support”.
      </Trans>
      <Trans parent="blockquote">
        <strong>PubNative - Ionut Ciobotaru, Co-Founder, CEO:</strong> “Mobile
        Apps are arguably the main medium for information distribution in
        developing economies. As they will potentially be hit hardest, that's
        where help is needed most. We are humbled by the industry initiatives
        we've seen and particularly grateful for the work #AppsFightCovid has
        undertaken.”
      </Trans>
      <Trans parent="h2">
        Checkout What Leading Magazines In The Industry Wrote About
        #AppsFightCovid:
      </Trans>
      <ul>
        <Trans parent="li">
          AdExchanger:
          <br />
          <a
            href="https://www.adexchanger.com/mobile/mobile-apps-ally-to-fight-covid-19-misinfo-in-developing-countries/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Mobile Apps Ally To Fight COVID-19 Misinfo In Developing Countries
          </a>
        </Trans>
        <Trans parent="li">
          Gründerszene:
          <br />
          <a
            href="https://www.gruenderszene.de/technologie/tim-koschella-werbeanzeigen-corona-initiative"
            target="_blank"
            rel="noreferrer noopener"
          >
            Ein Berliner Gründer will Corona in Entwicklungsländern bekämpfen
          </a>
        </Trans>
        <Trans parent="li">
          MobileMarketingMagazine:
          <br />
          <a
            href="https://mobilemarketingmagazine.com/kayzen-launches-appsfightcovid-to-prevent-the-spread-of-covid-19-in-emerging-countries"
            target="_blank"
            rel="noreferrer noopener"
          >
            Kayzen launches #AppsFightCovid to prevent the spread of COVID-19 in
            emerging countries
          </a>
        </Trans>
      </ul>
      <Trans parent="h2">Why Did We Start This Initiative?</Trans>
      <Trans parent="p">
        We believe that to fight the expansion of COVID-19, the world requires
        solidarity, and only through teamwork, we can stop the spreading of the
        virus. We wish to fight fake news and misinformation by spreading
        reliable information on how to prevent infection in countries where we
        could make a change. Here are only a few examples of fake news being
        spread during the Coronavirus pandemic:
      </Trans>
      <blockquote>
        <Trans>
          “Sonko, the governor of the Kenyan capital city, Nairobi, confirmed in
          a media briefing on Tuesday that his care packages include a few small
          bottles of the cognac, Hennessy. The governor justified the inclusion
          of alcohol as “throat sanitizer”. “I think from the research conducted
          by the World Health Organization and various organizations, it has
          been believed that alcohol plays a major role in killing the
          coronavirus” Sonko said in a video.
        </Trans>{' '}
        <a
          href="https://edition.cnn.com/2020/04/17/africa/kenya-governor-alcohol-and-coronavirus/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>CNN</strong>
        </a>
      </blockquote>
      <blockquote>
        <Trans>
          “Stories have been circulating online suggesting it's dangerous to
          take ibuprofen if you have coronavirus. Alongside genuine medical
          advice, false messages have been spreading, distorting the facts
          Speaking to the BBC, medical professionals said that ibuprofen is not
          recommended for managing coronavirus symptoms”.
        </Trans>{' '}
        <a
          href="https://www.bbc.com/news/51929628"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>BBC</strong>
        </a>
      </blockquote>
      <blockquote>
        <Trans>
          “A photograph circulating on social media allegedly shows advice from
          the United Nations Children's Fund (UNICEF) in relation to the new
          coronavirus. The document, which includes the logo of the
          organization, lists a series of recommendations that include “drink
          hot water” and “stay away from ice cream”.
        </Trans>{' '}
        <a
          href="https://www.reuters.com/article/uk-factcheck-coronavirus-unicef/false-claim-unicef-recommends-sun-exposure-gargling-with-salt-water-and-stay-away-from-ice-cream-to-prevent-coronavirus-idUSKBN21F0SS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>Reuters</strong>
        </a>
      </blockquote>
      <Trans parent="h2">What Happens With Money Donated?</Trans>
      <Trans parent="p">
        100% of the money you donate will be spent to reach people through
        buying the in-app media placements where the information campaign is
        shown; Kayzen provides its technology platform to target, deliver and
        optimize the ads free of charge to this initiative. All incurred server
        and related costs are covered by Kayzen; Everyone involved in the
        #AppsFightCovid is a pro bono volunteer.
      </Trans>
    </div>
  );
}

export default About;
