import vars from './_vars.scss';

export default function styles() {
  const heightBreakpoint = parseInt(vars['height-breakpoint']);
  const { innerHeight } = window;
  const prefix = innerHeight <= heightBreakpoint ? '-sm' : '';
  return {
    innerHeight,
    heightBreakpoint,
    spacing: parseInt(vars[`spacing${prefix}`]),
    barHeight: parseInt(vars[`bar-height${prefix}`]),
  };
}
