import React, { useState, useEffect } from 'react';
import { ReactComponent as BurgerClosedIcon } from 'icons/burger-closed.svg';
import { ReactComponent as BurgerOpenIcon } from 'icons/burger-open.svg';
import './Header.scss';
import About from 'components/About/About';
import Language from 'components/Language/Language';
import How from 'components/How/How';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';
import { useTranslation } from 'react-i18next';
import _vars from '_vars.scss';

export default function Header() {
  const { t } = useTranslation();
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [menuItemExpanded, setMenuItemExpanded] = useState(null);
  const [dropdownHeight, setDropdownHeight] = useState(
    window.innerHeight - 100
  );

  useEffect(() => {
    let id;
    window.addEventListener('resize', () => {
      if (window.innerWidth > _vars['width-breakpoint']) {
        setMenuExpanded(false);
        setMenuItemExpanded(null);
      } else {
        clearTimeout(id);
        id = setTimeout(() => setDropdownHeight(window.innerHeight - 100), 100);
      }
    });
  }, []);

  function toggleMenu(e) {
    e.preventDefault();
    setMenuExpanded(!menuExpanded);
  }

  function toggleMenuItem(id) {
    return (e) => {
      e.preventDefault();
      setMenuItemExpanded(menuItemExpanded === id ? null : id);
    };
  }

  return (
    <div className="Header">
      <div className="bar">
        <a href="" className="burger" onClick={toggleMenu}>
          {menuExpanded ? <BurgerOpenIcon /> : <BurgerClosedIcon />}
        </a>
        <a href="/" className="logo">
          <LogoIcon />
        </a>
        <div style={{ flex: 1 }}></div>
        <Language />
        <a
          href="https://kayzen-programmatic.typeform.com/to/cFID6e"
          target="_blank"
          rel="noopener noreferrer"
          className="donate"
        >
          {t('Become a Donor')}
        </a>
      </div>
      {menuExpanded ? (
        <ul className="dropdown" style={{ maxHeight: dropdownHeight }}>
          <li>
            <a href="" onClick={toggleMenuItem('about')}>
              {t('About the initiative')}
              <i
                className={`arrow ${
                  menuItemExpanded === 'about' ? 'up' : 'down'
                }`}
              ></i>
            </a>
            {menuItemExpanded === 'about' ? (
              <div className="menu-item-content">
                <About />
              </div>
            ) : (
              ''
            )}
          </li>
          <li>
            <a href="" onClick={toggleMenuItem('how')}>
              {t('How it works')}
              <i
                className={`arrow ${
                  menuItemExpanded === 'how' ? 'up' : 'down'
                }`}
              ></i>
            </a>
            {menuItemExpanded === 'how' ? (
              <div className="menu-item-content">
                <How />
              </div>
            ) : (
              ''
            )}
          </li>
          <li>
            <a
              href="https://kayzen-programmatic.typeform.com/to/cFID6e"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Become a Donor')}
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/AppsCovid"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Latest news')}
            </a>
          </li>
          <li>
            <a href="https://kayzen.io">
              {t('The people behind the initiative')}
            </a>
          </li>
        </ul>
      ) : (
        ''
      )}
    </div>
  );
}
